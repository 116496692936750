/* ENTER TRANSITION */
/* Declare transition start properties*/
.slide-enter {
    transform: translateX(-100vw);
    opacity: 0;
    position: absolute;
    
}

/* Declare transition properties */
.slide-enter.slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 300ms linear 300ms;
}

/* EXIT TRANSITION */
.slide-exit {
    transform: translateX(0);
    opacity: 1;
    
}

.slide-exit.slide-exit-active {
    transform: translateX(100vw);
    opacity: 0;
    transition: all 300ms linear
}


.snackbar {
	position: absolute;
	width: 300px;
	z-index: 1000;
	right: 20px;
	top: 20px;
}