// Sidebar dark

.app-sidebar {

  &--overlay {
    &:after {
      content: '';
      background: $black;
      opacity: .45;
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & > * {
      z-index: 4;
    }
  }

  &--dark {
    .app-sidebar--header {
      background: transparent;

      .collapse-sidebar-btn {
        background: transparent;
        color: rgba($white, .8);
        opacity: 1;

        &:hover {
          background: rgba($black, .2);
          color: $white;
        }
      }
      .expand-sidebar-btn {
        border: $white solid 2px;
        background: $white;
        color: $primary;
        box-shadow: $box-shadow-sm;
      }
      &::after {
        background: rgba($white, .15);
      }
    }

    // Logo wrapper

    .app-sidebar-logo {
      &--icon {
        // background: ;
        box-shadow: none;
      }

      &--text {
        span {
          color: $white;
          opacity: .7;
        }

        b {
          color: $white;
        }
      }

      &:hover {
        color: $black;
      }
    }

    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      background: $white !important;
    }
  }
}
