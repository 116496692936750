$pdf-prim-col: rgb(59, 100, 174);
$pdf-prim-col-cancel: rgb(255, 0, 0);

.page {
	background: white;
	display: block;
	margin: 0 auto;
	margin-bottom: 0.5cm;
	box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);

}

.analytics-report {
	counter-reset: h2counter h3counter; 

	h2:before {
		content: counter(h2counter) ".\0000a0\0000a0";
		counter-increment: h2counter;

	}
	.h3-counter-reset {
		counter-reset: h3counter;
	}

	h2 {
		counter-reset: h3counter;
	}

	h3:before {
		content: counter(h2counter) "." counter(h3counter) ".\0000a0\0000a0";
		counter-increment: h3counter;
	}
}

.page {
	padding: 1.5cm 1cm;
}

.page[data-size="A4"] {
	width: 21cm;
	height: 29.5cm;
}

.page[data-size="A4"][data-layout="landscape"] {
	width: 29.7cm;
	height: 20.8cm;
}

.page[data-size="A3"] {
	width: 29.7cm;
	height: 42cm;
}

.page[data-size="A3"][data-layout="landscape"] {
	width: 42cm;
	height: 29.7cm;
}

.page[data-size="A5"] {
	width: 14.8cm;
	height: 21cm;
}

.page[data-size="A5"][data-layout="landscape"] {
	width: 21cm;
	height: 14.8cm;
}

@media print {

	.app-inner-content-layout--main>.container-fluid {
		padding: 0 !important;
		margin: 0 !important;
	}

	.page {
		page-break-after: always;
		box-shadow: none;
	}

	.page:last-of-type {
		page-break-after: avoid;
	}

	@page {
		size: A4 landscape !important;
		margin: 0;
	}

	.analytics-report {
		position: relative;
		overflow: visible !important;
	}

	.app-wrapper,
	.app-main,
	.app-content {
		background: none !important;
		width: auto !important;
		height: auto !important;
		max-height: none !important;
		padding: 0 !important;
		margin: 0 !important;
		box-shadow: none !important;
		overflow: hidden !important;
	}

	.app-header,
	.app-sidebar,
	footer,
	.confirm-box,
	.alert,
	.resize-triggers {
		display: none !important;
	}

}