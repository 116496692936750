//  Core

.app-sidebar {
	&--header {
		padding: 15px ($layout-spacer / 2);
		min-height: $header-height;
		//height: $header-height;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		margin-bottom: ($layout-spacer / 4);

		.expand-sidebar-btn {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&::after {
			content: "";
			width: 100%;
			left: 0;
			bottom: 0;
			height: 1px;
			position: absolute;
		}
	}

	.toggle-mobile-sidebar-btn {
		display: none;
	}

	// Responsive

	@include media-breakpoint-down(md) {
		.toggle-mobile-sidebar-btn {
			display: block;
		}
	}
}

// Logo wrapper

.app-sidebar-logo {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-height: 200px;
	justify-content: center;
	transition: all 0.5s $app-sidebar-transition;
	margin: 10px 0px;

	&--icon {
		@include border-radius($border-radius-sm);
		// max-width: 40px;
		// height: 40px;
		display: flex;
		align-items: center;
		align-content: center;
		box-shadow: 1px 1px 3px rgba($black, .2);
		margin-bottom: 15px;

		img {
			width: 100%;
			max-width: 90px;
			margin: 0 auto;
			max-height: 100px;
			//animation: logo-spin infinite 20s linear;
		}
		svg {
			width: 100px;
		}
	}

	&--text {
		line-height: 1;
		padding: 0 0 0 ($spacer / 1.5);
		transition: all 0.5s $app-sidebar-transition;

		span {
			display: block;
			text-transform: uppercase;
			font-size: $font-size-sm / 1.2;
			color: $white;
			opacity: .7;
			padding: 4px 0;
		}

		b {
			font-size: $font-size-lg * 1.1;
			font-weight: bold;
			color: $white;
		}
	}

	&:hover {
		color: $black;
	}
}

@-webkit-keyframes logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

// Themes

@import "themes/dark";
@import "themes/light";

// Modifiers

@import "modifiers/collapsed";